<template>
  <div class="custom-landing">
    <div class="title" @click="goBack()">
      <div><img class="back" src="../../static/images/leftArrow.png" /></div>
      全国报名大数据
    </div>
    <div class="data-info">
      <div class="data-item">
        <div class="data-tit">全国报名人数</div>
        <div><span class="red data-num">{{dataInfo.approvedNumber}}</span>人</div>
      </div>
      <div class="data-item">
        <div class="data-tit">全国招录人数</div>
        <div><span class="red data-num">{{dataInfo.numberCandidates}}</span>人</div>
      </div>
    </div>
    <div class="data-info">
      <div class="data-item">
        <div class="data-tit">平均报招考比</div>
        <div><span class="red data-num">{{dataInfo.averageAge}}</span></div>
      </div>
      <div class="data-item">
        <div class="data-tit">最高报招考比</div>
        <div><span class="red data-num">{{dataInfo.averageMax}}</span></div>
      </div>
    </div>
    <div class="post">
      <div
        class="specialty-list"
        v-for="item in postList"
        :key="item.value"
        :class="postIndex === item.value ? 'specialty-active' : ''"
        @click="goPost(item.value)"
      >
        <span>{{ item.label }}</span>
      </div>
    </div>
    <div class="list">
      <div class="flex margin" v-if="dataList && dataList.length" style="flex-wrap: wrap">
        <div
          v-for="(item, index) in dataList"
          :key="item.value"
          class="data-list"
          :class="getBorder(index) === 0 ? 'none-border' : ''"
          @click="goDetail(item)"
        >
          <div class="list-tit">{{item.province}}</div>
          <div class="list-item">报名人数 <span class="red margin-left-xs">{{item.approvedNumber}}</span></div>
          <div class="list-item">招考人数 <span class="red margin-left-xs">{{item.numberCandidates}}</span></div>
          <div class="list-item">总岗位数 <span class="red margin-left-xs">{{item.postNumber}}</span></div>
        </div>
      </div>
      <div v-else style="margin-top: 50px; font-size: 20px; text-align: center">
        暂无数据
      </div>
    </div>
  </div>
</template>

<script>
import { getRegistrationCount } from '@/api/army'
export default {
  name: 'index',
  data() {
    return {
      postIndex: '1',
      postList: [
        {
          value: '1',
          label: '管理岗',
        },
        {
          value: '2',
          label: '技能岗',
        },
      ],
      dataInfo: {},
      dataList: [],
      queryParam: {
        page: 1,
        size: 10,
      },
      total: 0,
      totalPage: 0,
      isData: false,
    }
  },
  mounted() {
    this.getRegistrationCount()
  },
  methods: {
    goBack() {
      this.$router.back()
    },
    goPost(value) {
      this.dataList = []
      this.postIndex = value
      if (value === '1') {
        this.getRegistrationCount()
      }
    },
    getBorder(index) {
      const num = (index + 1) % 3
      // console.log('wwww', num)
      return num
    },
    getRegistrationCount() {
      getRegistrationCount().then((res) => {
        this.dataList = res.data.areaSituationList
        this.dataInfo = res.data
      })
    },
    goDetail(item) {
      this.$router.push({ path: 'armyDataDetail', query: { province: item.province } })
    },
  },
}
</script>

<style scoped>
.custom-landing {
  width: 100%;
  height: 100vh;
  font-size: 16px;
  overflow: scroll;
}
.title {
  width: 100%;
  height: 45px;
  line-height: 45px;
  background-color: red;
  color: #fff;
  font-size: 20px;
  text-align: center;
  position: relative;
}
.back {
  width: 20px;
  height: 20px;
  position: absolute;
  top: 10px;
  left: 10px;
}
.data-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 50%;
  flex-wrap: wrap;
}
.data-info {
  display: flex;
  align-items: center;
  padding: 20px 20px 0 20px;
}
.red {
  color: red;
}
.data-tit {
  font-size: 18px;
  color: #000;
  font-weight: bold;
  margin-bottom: 15px;
}
.data-num {
  font-size: 16px;
  font-weight: bold;
}
.post {
  height: 50px;
  line-height: 50px;
  display: flex;
  justify-content: space-around;
  font-size: 16px;
  color: #666;
  border-bottom: 2px solid #e2e2e2;
  margin: 20px 15px 0 15px;
}
.specialty-list {
  font-size: 16px;
}
.specialty-active {
  font-size: 18px;
  font-weight: bold;
  color: #000;
}
.list {
}
.data-list {
  margin: 20px 5px;
  width: 30%;
  display: flex;
  justify-content: space-around;
  flex-direction: column;
  font-size: 12px;
  text-align: center;
  border-right: 1px solid #ececec;
}
.list-tit {
  font-size: 16px;
  font-weight: bold;
  color: #000;
  margin-bottom: 5px;
}
.list-item {
  line-height: 24px;
  display: flex;
  text-align: center;
  align-content: center;
  align-items: center;
  color: #000;
  font-size: 12px;
  margin: 0 5px;
}
.list-item span {
  font-weight: bold;
}
.flex {
  display: flex;
}
.none-border {
  border-right: none;
}
.margin {
  margin: 0 10px;
}
.margin-left-xs {
  margin-left: 3px;
}
</style>
