<template>
  <div class="custom-landing" @scroll="handleScroll($event)">
    <div class="title" @click="goBack()">
      <div><img class="back" src="../../static/images/leftArrow.png" /></div>
      报名大数据
    </div>
    <div class="detail-head">
      <div class="detail-tit">
        2024年军队文职考试{{ $route.query.province }}报名人数统计
      </div>
      <div class="datail-time">
        <span style="margin-left: 10px">数据来源：{{dataInfo.dataSource}}</span>数据更新时间：{{dataInfo.updatedAt}}
      </div>
    </div>
    <div class="detail-info">
      <div class="info-modal">
        <div>
          <div>招录人数</div>
          <div class="detail-num">{{ dataInfo.numberCandidates }}</div>
        </div>
        <div>
          <div>实时报名人数</div>
          <div class="detail-num" style="text-align: right">{{ dataInfo.approvedNumber }}</div>
        </div>
      </div>
      <div class="info-modal">
        <div>
          <div>竞争最激烈岗位</div>
          <div class="detail-num">{{ dataInfo.hotRate }}</div>
        </div>
        <div>
          <div>无人报考岗位</div>
          <div class="detail-num" style="text-align: right">{{ dataInfo.unmannedPost }}</div>
        </div>
      </div>
    </div>
    <div class="post-hint">
      为您匹配到<span>{{ total }}</span>个岗位
    </div>
    <div class="post-list" v-if="dataList && dataList.length > 0">
      <div v-for="item in dataList" :key="item.value" class="list" @click="goDetail(item)">
        <div class="list-tit">[{{ item.employerNumber }}]{{ item.employerName }}</div>
        <div class="list-num">
          招录数 {{ item.candidatesSum }} | 招录岗位数
          {{ item.postCodeSum }}
        </div>
        <div class="list-city">
          <div class="city">
            <img class="position-icon" src="../../static/images/position.png" /><span
              >{{ item.workingConditions }}</span
            >
          </div>
          <div class="list-btn">查看具体岗位</div>
        </div>
      </div>
      <div
        v-if="isData"
        style="margin-top: 10px; font-size: 14px; text-align: center; margin-bottom: 20px"
      >
        没有更多数据了
      </div>
      <!-- <div
        v-if="!isData"
        style="margin-top: 10px; font-size: 14px; text-align: center; margin-bottom: 20px"
      >
        数据加载中。。。
      </div> -->
    </div>
    <div v-else style="margin-top: 50px; font-size: 20px; text-align: center">
      暂无数据
    </div>
  </div>
</template>

<script>
import { getCompanyList } from '@/api/army'
export default {
  name: 'index',
  data() {
    return {
      postIndex: '1',
      postList: [
        {
          value: '1',
          label: '推荐',
        },
        {
          value: '2',
          label: '按招录人数排序',
        },
      ],
      dataInfo: {},
      dataList: [],
      queryParam: {
        page: 1,
        size: 10,
      },
      total: 0,
      totalPage: 0,
      isData: false,
    }
  },
  mounted() {
    this.getCompanyList()
  },
  methods: {
    goBack() {
      this.$router.back()
    },
    goPost(value) {
      this.dataList = []
      this.postIndex = value
      if (value === '1') {
        this.getCompanyList()
      }
    },
    getCompanyList() {
      getCompanyList({
        page: this.queryParam.page,
        perPage: this.queryParam.size,
        province: this.$route.query.province,
      }).then((res) => {
       // this.dataList = res.data.list
        const list = res.data.list
        for (const item of list) {
          this.dataList.push(item)
        }
        this.dataInfo = res.data
        this.total = res.data.page.total
        this.totalPage = res.data.page.totalPage
        if (this.totalPage <= this.queryParam.page) {
          this.isData = true
        } else {
          this.isData = false
        }
      })
    },
    handleScroll(e) {
      let clientHeight = e.target.clientHeight
      let scrollTop = e.target.scrollTop
      let scrollHeight = e.target.scrollHeight
      if (this.totalPage <= this.queryParam.page) {
        return
      }
      if (clientHeight + scrollTop >= scrollHeight - 10) {
       // console.log('2222', this.queryParam.page)
        this.queryParam.page++
        this.getCompanyList()
      }
    },
    goDetail(item) {
      this.$router.push({ path: 'armyDataList', query: { employerNumber: item.employerNumber } })
    },
  },
}
</script>

<style scoped>
.custom-landing {
  width: 100%;
  height: 100vh;
  font-size: 16px;
  background-color: #f4f4f4;
  color: #000;
  overflow: scroll;
}
.title {
  width: 100%;
  height: 45px;
  line-height: 45px;
  background-color: red;
  color: #fff;
  font-size: 20px;
  text-align: center;
  position: relative;
}
.back {
  width: 20px;
  height: 20px;
  position: absolute;
  top: 10px;
  left: 10px;
}
.post {
  height: 50px;
  line-height: 50px;
  display: flex;
  justify-content: space-around;
  font-size: 16px;
  color: #666;
  border-bottom: 2px solid #e2e2e2;
  margin: 20px 15px 0 15px;
}
.specialty-list {
  font-size: 16px;
}
.specialty-active {
  font-size: 18px;
  font-weight: bold;
  color: #000;
}
.red {
  color: red;
}
.list-item span {
  font-weight: bold;
}
.flex {
  display: flex;
}
.none-border {
  border-right: none;
}
.margin {
  margin: 0 10px;
}
.margin-left-xs {
  margin-left: 3px;
}
.detail-head {
  background-color: red;
}
.detail-tit {
  font-size: 20px;
  font-weight: bold;
  color: #fff;
  text-align: center;
  padding: 30px 60px;
}
.datail-time {
  display: flex;
  font-size: 12px;
  color: #fff;
  flex-direction: row-reverse;
  margin-right: 20px;
  padding-bottom: 40px;
}
.detail-info {
  border-radius: 10px 10px 0 0;
  background-color: #fff;
  padding: 20px;
  margin-top: -20px;
}
.info-modal {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}
.detail-num {
  font-size: 16px;
  font-weight: bold;
  color: red;
}
.post-hint {
  height: 40px;
  line-height: 40px;
  background-color: #ffeded;
  color: #000;
  text-align: center;
  margin-top: 20px;
}
.list {
  background-color: #fff;
  padding: 20px;
  margin-top: 20px;
}
.list-tit {
  font-size: 18px;
  margin-bottom: 5px;
}
.list-num {
  font-size: 16px;
  margin-bottom: 5px;
}
.position-icon {
  width: 15px;
  height: 15px;
  margin-top: -2px;
}
.list-city {
  display: flex;
  justify-content: space-between;
}
.city {
  color: #666;
}
.city span {
  margin-top: 10px;
  margin-left: 5px;
}
.list-btn {
  border: 1px solid #666;
  border-radius: 5px;
  color: red;
  padding: 2px 5px;
  width: 100px;
  font-size: 14px;
}
.post-hint span {
  color: red;
  font-size: 20px;
}
</style>
