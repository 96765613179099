<template>
  <div class="custom-landing">
    <div class="title">
      军队文职
    </div>
    <div class="home">
      <div class="home-info" @click="goData()">
        <img class="home-img" src="../../static/images/data-icon.png" />
        <div>报名大数据</div>
      </div>
      <div class="home-info" @click="goSpecialty()">
        <img class="home-img" src="../../static/images/specialty-icon.png" />
        <div>按专业查岗</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'index',
  data() {
    return {}
  },
  mounted() {},
  methods: {
    goData() {
      this.$router.push({ path: 'armyData' })
    },
    goSpecialty() {
      this.$router.push({ path: 'armyIndex' })
    },
  },
}
</script>

<style scoped>
.custom-landing {
  width: 100%;
  height: 100vh;
  font-size: 14px;
}
.title {
  width: 100%;
  height: 45px;
  line-height: 45px;
  background-color: red;
  color: #fff;
  font-size: 20px;
  text-align: center;
  position: relative;
}
.home {
  border-radius: 10px;
  background-color: #fff;
  display: flex;
  padding: 20px;
  border: 1px solid #ececec;
  box-shadow: 2px 2px 5px 2px rgba(0, 0, 0, 0.1);
  margin: 20px;
  justify-content: space-around;
  font-weight: bold;
  color: #000;
  font-size: 16px;
}
.home-info {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.home-img {
  width: 40px;
  height: 40px;
  margin: 0 auto;
}
</style>
